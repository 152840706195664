import componentRegistry from '../registry.js';

/**
 * Side category toggler
 */
export default class SideCategory {
  constructor({ el }) {
    el.addEventListener('click', ({ target }) => {
      target.closest('.categoryMenu-list').classList.toggle('isClose');
    });
  }
}

componentRegistry.define('js-category-toggler', SideCategory);
