import componentRegistry from '../registry.js';
import MagnificPopup from '../../scripts/common/dynamic-imports/MagnificPopup.js';

export default class PopupVideo extends MagnificPopup {
  constructor({ el }) {
    super(el, {
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-fade',
      overflowY: 'hidden',
      fixedContentPos: true,
      removalDelay: 160,
      preloader: false,
    });
  }
}

componentRegistry.define('js-popupVideo', PopupVideo);
