import componentRegistry from '../registry.js';
import Overlay from '../overlay/index.js';
import './sidebar-toggler.less';

const JS_PREFIX = 'js-sidebar-toggler';
const CLASSNAMES = {
  ACTIVE: 'isActive',
  SIDEBAR: 'js-Sidebar',
};

/** Sidebar toggler Show/hide the sidebar on tablet portait mode */
export default class SidebarToggler {
  constructor({ el }) {
    this.el = el;
    this.sidebar = document.querySelector(`.${CLASSNAMES.SIDEBAR}`);

    if (!this.sidebar) {
      throw new TypeError(`sidebar element not found`);
    }

    this.overlay = new Overlay({
      // modifier: 'menu',
      spinner: false,
      closeOnClick: true,
    });
    this.overlay.addEventListener('close', () => this.hideSidebar());

    this.el.addEventListener('click', (e) => {
      e.preventDefault();
      this.showSidebar();
    });
  }

  showSidebar() {
    this.sidebar.classList.add(CLASSNAMES.ACTIVE);
    this.overlay.show();
  }

  hideSidebar() {
    this.sidebar.classList.remove(CLASSNAMES.ACTIVE);
  }
}

componentRegistry.define(JS_PREFIX, SidebarToggler);
