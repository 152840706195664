import '../../configuration/global-jquery.js';
import '../../components/header-search/index.js';
import '../../components/publi-carousel-view/index.js';
import '../../entries/index.js';
import '../../components/lazy-image/index.js';
import '../../components/expander/index.js';
import '../../components/n-carousel/index.js';
import '../../components/expert-posts/index.js';
import '../../components/v-carousel/index.js';
import '../../components/layer-switcher/index.js';
import '../../components/iframe-resizer/index.js';
import '../../components/popup-video/index.js';
//import '../../components/expansion-controller/index.js';
import '../../components/best-sales/index.js';
import '../../components/sidebar-toggler/index.js';
import '../../components/side-category/index.js';
import '../../components/popin-socloz/index.js';

import $ from 'jquery';

// FIXME remplace this with CSS
/* /PUBLI */
// Ad 300*250 HomePage (iframe)
var $thumbnail = $('.categoryMosaic-thumb');
var $advertisting = $('.categoryMosaic-advertising');
var $advertistingDiv = $('.categoryMosaic-advertising div');

if ($thumbnail.length) {
  // setInterval for IE 9/Edge (fix)
  var counter = 0;
  var looper = setInterval(function () {
    counter++;

    $(window)
      .on('load resize', function () {
        var height = $thumbnail.height();
        var width = $thumbnail.width();
        var iframeScale = 0;
        $advertisting.css('padding-bottom', height);
        $advertistingDiv.first().css('padding-bottom', height - 16 + 'px');

        if (width >= height) {
          iframeScale = width / 300;
        } else {
          iframeScale = (height - 17) / 250;
        }

        if (iframeScale > 1) {
          iframeScale = 1;
        }

        $advertistingDiv.first().css({
          '-webkit-transform': 'scale(' + iframeScale + ')',
          transform: 'scale(' + iframeScale + ')',
        });
      })
      .trigger('resize'); //on page load

    if (counter >= 5) {
      clearInterval(looper);
    }
  }, 1000);
}
