import componentRegistry from '../registry.js';
import { region, lang } from '../../configuration/Configuration.js';

let initPopinSocloz = false;

export default class PopinSocloz {
  constructor({ el }) {
    this.el = el;
    el.classList.add('socloz');
    this.initializePopin();
  }

  initializePopin() {
    if (!initPopinSocloz) {
      initPopinSocloz = true;
      const id = setInterval(() => {
        if (!this.isOneTrustValid()) return;
        this.trigger();
        clearInterval(id);
      }, 0);
    }
  }

  isOneTrustValid() {
    return (
      window.OneTrust && typeof OneTrust.IsAlertBoxClosedAndValid === 'function' && OneTrust.IsAlertBoxClosedAndValid()
    );
  }

  trigger() {
    if (!document.getElementById('soclozjs')) {
      const { idSocloz, dataCurrency } = this.getSoclozData();
      this.loadSoclozScript(idSocloz, dataCurrency, region, lang);
    }
  }

  getSoclozData() {
    let idSocloz = '';
    let dataCurrency = 'EUR';

    switch (region) {
      case 'BE':
        idSocloz = '2ae621f94b83572863abd8d1368bf659';
        break;
      case 'CH':
        idSocloz = '65cf8131bc09be0eda633ad23ce3653c';
        dataCurrency = 'CHF';
        break;
    }

    return { idSocloz, dataCurrency };
  }

  loadSoclozScript(idSocloz, dataCurrency, region, lang) {
    const soclozScript = document.createElement('script');
    soclozScript.id = 'soclozjs';
    soclozScript.src = 'https://api.socloz.com/v4/js/popup.js';

    soclozScript.setAttribute('data-socloz-app_key', idSocloz);
    soclozScript.setAttribute('data-socloz-country', region);
    soclozScript.setAttribute('data-socloz-lang', lang);
    soclozScript.setAttribute('data-socloz-cross_selling_link', `${location.protocol}//${location.host}/`);
    soclozScript.setAttribute('data-socloz-currency', dataCurrency);

    (document.head || document.body || document.getElementsByTagName('script')[0].parentNode).insertBefore(
      soclozScript,
      null
    );
  }
}

componentRegistry.define('js-popin-socloz', PopinSocloz);
