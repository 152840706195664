import componentRegistry from '../registry.js';
import NCarousel, { NCAROUSEL_EVENTS } from '../n-carousel/index.js';
import './videoCarousel.less';

const CSS_PREFIX = 'js-vCarousel';
const CLASSNAMES = {
  SLIDES: `${CSS_PREFIX}Slides`,
  SLIDE: `${CSS_PREFIX}Slide`,
  CARD: `${CSS_PREFIX}Card`,
  NAV: `${CSS_PREFIX}Nav`,
  ARROW_PREV: `${CSS_PREFIX}Prev`,
  ARROW_NEXT: `${CSS_PREFIX}Next`,
  METER: `${CSS_PREFIX}Meter`,
  PROGRESS: 'progress',
  HIDDEN: 'hidden',
};

export default class VCarousel {
  constructor({ el }) {
    this.el = el;
    this.autoInterval = 5000;
    this.carousel = new NCarousel({
      el: el.querySelector(`.${CLASSNAMES.SLIDES}`),
      options: {
        visibles: 1,
        animationDuration: 350,
        animationEase: 'linear',
        loop: true,
        auto: true,
        autoInterval: this.autoInterval,
        arrows: true,
        arrowPrev: this.el.querySelector(`.${CLASSNAMES.ARROW_PREV}`),
        arrowNext: this.el.querySelector(`.${CLASSNAMES.ARROW_NEXT}`),
        nativeScroll: false,
      },
    });

    this.items = Array.from(this.el.querySelectorAll(`.${CLASSNAMES.SLIDE}`));
    this.cards = Array.from(this.el.querySelectorAll(`.${CLASSNAMES.CARD}`));
    this.nav = this.el.querySelector(`.${CLASSNAMES.NAV}`);
    const meters = this.el.querySelectorAll(`.${CLASSNAMES.METER}`);
    this.isAutoPlaying = true;

    // When carousel initializes
    // Setted in CSS for better performance
    // meters.forEach((m) => (m.style.animationDuration = `${this.autoInterval}ms`));

    // Slides events
    this.carousel.el.addEventListener(NCAROUSEL_EVENTS.SCROLL_START, this.onScrollStart.bind(this));
    this.carousel.el.addEventListener(NCAROUSEL_EVENTS.SCROLL_END, this.onScrollEnd.bind(this));
    this.onScrollStart({ detail: { currentIndex: 0 } });
    this.onScrollEnd({ detail: { currentIndex: 0 } });

    // Autoplay
    this.carousel.el.addEventListener(NCAROUSEL_EVENTS.CLEAR_AUTOPLAY, () => {
      meters.forEach((m) => {
        m.style.display = 'none';
      });
      this.isAutoPlaying = false;
    });

    // Tracking
    this.el.dispatchEvent(
      new CustomEvent('play', {
        bubbles: true,
      })
    );
  }

  onScrollStart({ detail: { currentIndex, prevIndex } }) {
    const currentCard = this.cards[prevIndex];
    // Hide current card
    currentCard?.classList.add(CLASSNAMES.HIDDEN);

    if (this.isAutoPlaying) {
      // Reset current progress bar
      currentCard?.querySelector(`.${CLASSNAMES.METER}`).classList.remove(CLASSNAMES.PROGRESS);

      // Run current progress bar
      this.cards[currentIndex]?.querySelector(`.${CLASSNAMES.METER}`).classList.add(CLASSNAMES.PROGRESS);
    }

    // Pause current video
    this.items[prevIndex]?.querySelector('video').load();

    // Next video
    const nextVideo = this.items[currentIndex]?.querySelector('video');
    if (!nextVideo) {
      return;
    }

    // Load next video poster with its data-poster attribute
    if (nextVideo.dataset.poster) {
      nextVideo.setAttribute('poster', nextVideo.dataset.poster);
      nextVideo.removeAttribute('data-poster');
    }

    // Play next video
    nextVideo.querySelectorAll('source').forEach((el) => {
      if (el.dataset.src) {
        el.src = el.dataset.src;
        el.removeAttribute('data-src');
      }
    });

    nextVideo.play();
  }

  onScrollEnd({ detail: { currentIndex } }) {
    // Display current card
    this.cards[currentIndex]?.classList.remove(CLASSNAMES.HIDDEN);

    if (this.nav) {
      // Display current slide number in navigation (currentIndex is 0 based)
      this.nav.innerHTML = currentIndex + 1;
    }
  }
}

componentRegistry.define('js-VCarousel', VCarousel);
